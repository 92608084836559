<template>
  <div class="container-wrapper">
    <div class="container main-page">
      <UILogo class="main-page__logo" />
      <div class="main-page__content">
        <div class="main-page__content-description">
          <h1>
            Turn your contacts <br />
            into <span>MONEY</span>
          </h1>
          <img
            class="main-page__content-mobile-image"
            width="206"
            height="206"
            src="/images/mobile-main-image.png"
            alt="Satisfied customer"
          />
          <div class="main-page__content-points">
            <div class="main-page__content-point main-page__content-point--left">
              <span class="main-page__content-point-block"></span>
              <span class="main-page__content-point-text">
                Fill the registration form and get <br />
                immediate access to the portal
              </span>
            </div>
            <div class="main-page__content-point main-page__content-point--right">
              <span class="main-page__content-point-block"></span>
              <span class="main-page__content-point-text">
                Invite your first client from your <br />
                contacts. As easy as it sounds!
              </span>
            </div>
          </div>
          <div class="main-page__content-buttons">
            <NuxtLink no-prefetch class="main-page__content-button" to="login">
              <UIButton id="login-button">Log in</UIButton>
            </NuxtLink>
            <NuxtLink
              no-prefetch
              class="main-page__content-button main-page__content-button--create"
              :to="isMobile ? 'signup/email' : 'signup'"
            >
              <UIButton id="signup-button" variant="secondary">Create account</UIButton>
            </NuxtLink>
          </div>
        </div>
        <div class="main-page__content-image">
          <div class="main-page__content-image-bg">
            <img
              class="main-page__content-image-bg-image"
              width="646"
              height="646"
              src="/images/dashboard/background.png"
              alt="Satisfied customer"
            />
          </div>
          <div class="main-page__content-image-quote">
            <span>*</span>
            <p>
              Change the way you use your contacts and <br />
              start to earn with ThriveState
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { SITE_NAME } from '~/constants'

definePageMeta({
  title: SITE_NAME,
  layout: false,
  middleware: 'is-auth-route',
})

const { isMobile } = useAppBreakpoints()
</script>

<style scoped lang="scss">
@import '@/styles/variables.scss';

.container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 20px 47px 0 46px;
  min-height: 100dvh;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1023px) {
    padding: 16px;
  }
}

.container-wrapper {
  background: $bg;
  min-height: 100dvh;

  .main-page {
    &__logo {
      margin-bottom: 8px;
      color: $black-100;
      margin-left: 56px;

      @media screen and (max-width: 1023px) {
        text-align: center;
        margin: 0 auto;
      }
    }

    &__content {
      display: grid;
      gap: 40px;
      grid-template-columns: minmax(250px, 668px) minmax(300px, 593px);
      justify-content: space-between;
      padding-bottom: 19px;
      margin: auto 0;

      @media screen and (max-width: 1023px) {
        grid-template-columns: 1fr;
        padding-bottom: 0;
        margin: unset;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }

      &-mobile-image {
        display: none;
        margin-left: auto;

        @media screen and (max-width: 1023px) {
          display: block;
        }
      }

      &-description {
        margin-left: 56px;

        h1 {
          font-size: 64px;
          line-height: 86.4px;
          font-weight: 500;
          margin-top: 24px;
          margin-bottom: 47px;

          span {
            font-style: italic;
            font-weight: 700;
          }
        }

        @media screen and (max-width: 1305px) {
          margin-left: 32px;

          h1 {
            font-size: 46px;
            line-height: 66px;
            margin-bottom: 16px;
            margin-top: 55px;
          }
        }

        @media screen and (max-width: 1023px) {
          margin-left: 0;
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          max-width: 288px;
          margin: 0 auto;

          h1 {
            font-size: 32px;
            line-height: 43.2px;
            margin-top: 16px;
            text-align: center;
          }
        }
      }

      &-point,
      &-buttons {
        max-width: 463px;
      }

      &-point {
        position: relative;
        display: flex;

        &-block {
          display: block;
          width: 80px;
          height: 94px;

          @media screen and (max-width: 1305px) {
            width: 50px;
            height: 55px;
          }

          @media screen and (max-width: 1023px) {
            width: 40px;
            height: 40px;
          }
        }

        &-text {
          position: absolute;
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
          top: 63px;

          @media screen and (max-width: 1305px) {
            top: 30px;
          }

          @media screen and (max-width: 1023px) {
            top: 21px;
            font-size: 14px;
          }
        }

        &--left {
          min-height: 127px;

          .main-page__content-point-block {
            border-radius: 40px 40px 40px 0px;
            background: $additional-mint-100;
          }
          .main-page__content-point-text {
            left: 22px;
          }

          @media screen and (max-width: 1305px) {
            min-height: 95px;
          }

          @media screen and (max-width: 1023px) {
            min-height: 61px;

            .main-page__content-point-text {
              left: 8px;
            }
          }
        }

        &--right {
          min-height: 131px;
          justify-content: flex-end;

          .main-page__content-point-block {
            border-radius: 40px 40px 0px 40px;
            background: $additional-salmon-100;
          }

          .main-page__content-point-text {
            right: 22px;
          }

          @media screen and (max-width: 1305px) {
            min-height: 90px;
          }

          @media screen and (max-width: 1023px) {
            min-height: 61px;

            .main-page__content-point-text {
              right: 8px;
            }
          }
        }
      }

      &-buttons {
        display: flex;
        gap: 16px;
        margin-top: 60px;
        max-width: 463px;

        a {
          width: 50%;

          .button {
            width: 100%;
          }
        }

        @media screen and (max-width: 1305px) {
          margin-top: 20px;
        }

        @media screen and (max-width: 1023px) {
          flex-direction: column;
          gap: 8px;

          .main-page__content-button {
            width: 288px;
          }
        }
      }

      &-image {
        max-width: 593px;
        display: flex;
        flex-direction: column;

        @media screen and (max-width: 1023px) {
          display: none;
        }

        &-bg {
          position: relative;

          &-image {
            width: 100%;
            height: 100%;
          }
        }

        &-quote {
          display: flex;
          margin-left: auto;
          margin-right: 48px;
          margin-top: 15px;

          @media screen and (max-width: 1305px) {
            margin-right: 16px;
          }

          span {
            display: block;
            color: $gold-primary-100;
            margin-right: 16px;
            font-size: 40px;
            font-weight: 600;
            line-height: 150%;
          }

          p {
            font-size: 20px;
            font-weight: 400;
            line-height: 150%;
            margin-top: 6px;
          }
        }
      }
    }
  }
}
</style>
